<template>
    <div class="container">  
        <!-- <div class="row ">
            <div class="col-lg-4 col-md-6">
                <span class="d-flex fs-5 fw-bold">
                    <i class="ri-map-pin-fill fs-1 mx-1 text-warning"></i> 5F Ginza Daiei Building, 1-16-7 Ginza,<br> Chuo-ku, Tokyo - 104-0061.
                </span>
            </div>
            <div class="col-lg-4 col-md-6">
                <span class="d-flex justify-content-center">
                    <i class="ri-mail-open-line fs-1 mx-1 text-warning"></i> <a href="mailto:contact@bigtreeconsulting.jp" class="mt-2 fs-5 fw-bold">contact@bigtreeconsulting.jp</a>
                </span>
            </div>
            <div class="col-lg-4 col-md-6">
                <span class="d-flex justify-content-end">
                    <i class="ri-phone-fill fs-1 mx-1 text-warning"></i> <a href="tel:+810343608694" class="mt-2 fs-5 fw-bold"> (+81)-3-4360-8694</a>
                </span>
            </div>
        </div> -->
        <div class="contact-area">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-12">
                    <ul class="ctopbar-information">
                        <li>
                            <i class="ri-map-pin-fill fs-1 text-warning"></i><span class="text-dark fs-5 fw-bold"><div style="margin-left: 15px;">5F Ginza Daiei Building, 1-16-7 Ginza,</div><div style="margin-left: 15px;">Chuo-ku, Tokyo - 104-0061.</div></span>
                        </li>
                        <li>
                            <i class="ri-mail-open-line fs-1 text-warning"></i><span class="text-dark fs-5 fw-bold" style="margin-left: 15px;"><a href="mailto:contact@bigtreeconsulting.jp">contact@bigtreeconsulting.jp</a></span>
                        </li>
                        <li>
                            <i class="ri-phone-fill fs-1 text-warning"></i><span class="text-dark fs-5 fw-bold" style="margin-left: 15px;"><a href="tel:+810343608694"> (+81)-3-4360-8694</a></span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="map-location">
            <!-- old
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d102948.35266648312!2d-115.15540073403864!3d36.26047650441708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c8c2b00ad43d33%3A0x22c7fa13f5acf526!2sNorth%20Las%20Vegas%2C%20NV%2C%20USA!5e0!3m2!1sen!2sbd!4v1639919075838!5m2!1sen!2sbd"></iframe>
            -->
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12964.465775080906!2d139.7704395!3d35.6741347!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc078dd357ffbd421!2z44Oq44O844K444Oj44K5IOmKgOW6p--8keS4geebrg!5e0!3m2!1sen!2sin!4v1657011453148!5m2!1sen!2sin"></iframe>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Map'
}
</script>
<style>
.map-location iframe{
    height: 650px !important;
}
.contact-area{
    padding-top: 15px;
    padding-bottom: 15px;
}
.ctopbar-information{
    padding-left: 0;
    margin-bottom: 0;
    width: 100%;
    gap: 10%;
    display: flex;
    flex-wrap: wrap;
}
.ctopbar-information li{
    list-style-type: none;
    display: inline-block;
    font-weight: 400;
    margin-right: 20px;
    position: relative;
    padding-left: 25px;
}
.ctopbar-information li i{
    position: absolute;
    top: 0;
    left: 0;
    font-size: 18px;
}
</style>