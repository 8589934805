<template>
    <div :class="['navbar-area navbar-style-two-with-color', { 'is-sticky': isSticky }]">
        <div class="main-navbar">
            <div class="container-fluid">
                <nav class="navbar navbar-expand-lg navbar-light" id="navbar">
                    <router-link class="navbar-brand" to="/">
                        <img src="../assets/images/bt-logo-800w.png" style="height: 120px !important;" alt="BigTree Conculting, Japan.">
                    </router-link>

                    <div
                        class="navbar-toggler"
                        @click="active = !active"
                        :aria-pressed="active ? 'true' : 'false'"
                        v-bind:class="{ active: button_active_state }"
                        v-on:click="button_active_state = !button_active_state"
                    >
                        <i class="ri-menu-line"></i>
                        <i class="ri-close-line"></i>
                    </div>

                    <div class="collapse navbar-collapse" :class="{ toggler: active }">
                        <ul class="navbar-nav ms-auto" id="mySidenav" v-scroll-spy-active="{class: 'active'}">
                            
                            <li class="nav-item" id="home-menu">
                                <a
                                v-scroll-to="{ el:'#home', offset: 0, lazy: false, easing: 'linear',force: true,duration: 0}"
                                class="nav-link fs-5"
                                data-scroll-spy-id="home"
                                href="javascript: void(0);"
                                @click="changeMenuActive('home')"
                                >{{ $t('home') }}</a>
                            </li>
                            <li class="nav-item" id="about_us-menu">
                                <a
                                v-scroll-to="{ el:'#about_us', offset: -100, lazy: false, easing: 'linear',force: true,duration: 0}"
                                class="nav-link fs-5"
                                data-scroll-spy-id="about_us"
                                href="javascript: void(0);"
                                @click="changeMenuActive('about_us')" 
                                >{{ $t('about_us') }}</a>
                            </li>
                            <li class="nav-item" id="services-menu">
                                <a
                                v-scroll-to="{ el:'#services', offset: -100, lazy: false, easing: 'linear',force: true,duration: 0}"
                                class="nav-link fs-5"
                                data-scroll-spy-id="services"
                                href="javascript: void(0);"
                                @click="changeMenuActive('services')"
                                >{{ $t('services') }}</a>
                            </li>
                            <li class="nav-item" id="careers-menu">
                                <a
                                v-scroll-to="{ el:'#careers', offset: -100, lazy: false, easing: 'linear',force: true,duration: 0}"
                                class="nav-link fs-5"
                                data-scroll-spy-id="careers"
                                href="javascript: void(0);"
                                @click="changeMenuActive('careers')"
                                >{{ $t('Careers') }}</a>
                            </li>
                            <li class="nav-item" id="contact-menu">
                                <a
                                v-scroll-to="{ el:'#contact', offset: -100, lazy: false, easing: 'linear',force: true,duration: 0}"
                                class="nav-link fs-5"
                                data-scroll-spy-id="contact"
                                href="javascript: void(0);"
                                @click="changeMenuActive('contact')" 
                                >{{ $t('contact') }}</a>
                            </li>                         
                            <!-- <div style="margin-right:10px;margin-top: 2px;">
                                <b-dropdown id="ddown1" :text="selectedlang" class="cmt-3" v-model="$i18n.locale" @change="changeLanguage" variant="image">
                                    <div v-if="newData.length > 0" >
                                        <b-dropdown-item  v-for="data in newData" 
                                            :key="data.id" :value="data.id"
                                            @click="$i18n.locale = data.id;changeLanguage(data.id)"
                                            >
                                            {{data.name}}
                                        </b-dropdown-item>
                                    </div>
                                </b-dropdown>
                            </div> -->
                        
                        </ul>
                    </div>                    
                </nav>
            </div>
        </div>
        <div 
            class="sidebarModal modal right"
            v-if="isSideMenu"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <button 
                        type="button" 
                        class="close" 
                        v-on:click="isSideMenuMethod(isSideMenu)"
                    >
                        <i class="ri-close-line"></i>
                    </button>

                    <div class="modal-body">
                        <router-link to="/">
                            <img src="../assets/images/bigtree-logo-dark.png" alt="logo">
                        </router-link>
                        <div class="sidebar-content">
                            <h3>About Us</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                            <div class="sidebar-btn">
                                <router-link to="/contact" class="default-btn">Let’s Talk</router-link>
                            </div>
                        </div>
                        <div class="sidebar-contact-info">
                            <h3>Contact Information</h3>

                            <ul class="info-list">
                                <li><i class="ri-phone-fill"></i><a href="tel:+810343608694">03-4360-8694</a></li>
                                
                                <li><i class="ri-mail-line"></i> <a href="mailto:contact@bigtreeconsulting.jp">contact@bigtreeconsulting.jp</a></li>

                                <li><i class="ri-map-pin-line"></i> 104-0061,Tokyo,Chuo-ku,Ginza 1-16-7,Ginza Daiei Building 5F</li>
                            </ul>
                        </div>
                        <ul class="sidebar-social-list">
                            <li><a href="https://facebook.com/" target="_blank"><i class="ri-facebook-fill"></i></a></li>
                            <li><a href="https://twitter.com/" target="_blank"><i class="ri-twitter-fill"></i></a></li>
                            <li><a href="https://linkedin.com/" target="_blank"><i class="ri-linkedin-fill"></i></a></li>
                            <li><a href="https://instagram.com/" target="_blank"><i class="ri-instagram-fill"></i></a></li>
                        </ul>
                         
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
export default {
    name: "NavbarStyleTwo",
    data() {
        return {
            isSticky: true,
            search: false,
            button_search_state: false,
            active: false,
            button_active_state: false,
            isSideMenu: false,
            show: false,
            button_show_state: false,
            newData: [
                {
                    id: 'en',
                    image: require('../assets/images/uk.png'),
                    name: 'ENG',
                },
                {
                    id: 'jp',
                    image: require('../assets/images/japan.png'),
                    name: '日本語',
                }
            ],
            name: "日本語",
            image: require("../assets/images/uk.png"),
            clicked: false,
            selectedlang:'日本語'
        };
    },
    mounted() {
        window.addEventListener("scroll", () => {            
            let scrollPos = window.scrollY;
            var element = "";
            var child = "";
            var offset = scrollPos;
            if(offset > 0 && offset < 497){               
                element = document.getElementById("home-menu");
                element.classList.add("active")
                child = element.firstElementChild;
                child.style.color = '#FEB302';
                element = document.getElementById("about_us-menu");
                element.classList.remove("active")
                child = element.firstElementChild;
                child.style.color = '#14042C';
                element = document.getElementById("services-menu");
                element.classList.remove("active")
                child = element.firstElementChild;
                child.style.color = '#14042C';
                element = document.getElementById("careers-menu");
                element.classList.remove("active")
                child = element.firstElementChild;
                child.style.color = '#14042C';
                element = document.getElementById("contact-menu");
                element.classList.remove("active")
                child = element.firstElementChild;
                child.style.color = '#14042C';
            }  
            if(offset > 497 && offset < 1420){
                element = document.getElementById("about_us-menu");
                element.classList.add("active")
                child = element.firstElementChild;
                child.style.color = '#FEB302';
                element = document.getElementById("home-menu");
                element.classList.remove("active")
                child = element.firstElementChild;
                child.style.color = '#14042C';
                element = document.getElementById("services-menu");
                element.classList.remove("active")
                child = element.firstElementChild;
                child.style.color = '#14042C';
                element = document.getElementById("careers-menu");
                element.classList.remove("active")
                child = element.firstElementChild;
                child.style.color = '#14042C';
                element = document.getElementById("contact-menu");
                element.classList.remove("active")
                child = element.firstElementChild;
                child.style.color = '#14042C';
            }     
            if(offset > 1420 && offset < 2530){ 
                element = document.getElementById("services-menu");
                element.classList.add("active")
                child = element.firstElementChild;
                child.style.color = '#FEB302';
                element = document.getElementById("home-menu");
                element.classList.remove("active")
                child = element.firstElementChild;
                child.style.color = '#14042C';
                element = document.getElementById("about_us-menu");
                element.classList.remove("active")
                child = element.firstElementChild;
                child.style.color = '#14042C';
                element = document.getElementById("careers-menu");
                element.classList.remove("active")
                child = element.firstElementChild;
                child.style.color = '#14042C';
                element = document.getElementById("contact-menu");
                element.classList.remove("active")
                child = element.firstElementChild;
                child.style.color = '#14042C';
            }     
            if(offset > 2530 && offset < 3100){ 
                element = document.getElementById("careers-menu");
                element.classList.add("active")
                child = element.firstElementChild;
                child.style.color = '#FEB302';
                element = document.getElementById("home-menu");
                element.classList.remove("active")
                child = element.firstElementChild;
                child.style.color = '#14042C';
                element = document.getElementById("about_us-menu");
                element.classList.remove("active")
                child = element.firstElementChild;
                child.style.color = '#14042C';
                element = document.getElementById("services-menu");
                element.classList.remove("active")
                child = element.firstElementChild;
                child.style.color = '#14042C';
                element = document.getElementById("contact-menu");
                element.classList.remove("active")
                child = element.firstElementChild;
                child.style.color = '#14042C';
            }     
            if(offset > 3190 && offset < 4000){ 
                element = document.getElementById("contact-menu");
                element.classList.add("active")
                child = element.firstElementChild;
                child.style.color = '#FEB302';
                element = document.getElementById("home-menu");
                element.classList.remove("active")
                child = element.firstElementChild;
                child.style.color = '#14042C';
                element = document.getElementById("about_us-menu");
                element.classList.remove("active")
                child = element.firstElementChild;
                child.style.color = '#14042C';
                element = document.getElementById("services-menu");
                element.classList.remove("active")
                child = element.firstElementChild;
                child.style.color = '#14042C';
                element = document.getElementById("careers-menu");
                element.classList.remove("active")
                child = element.firstElementChild;
                child.style.color = '#14042C';
            } 
        });  
    },
    methods: {
        isSideMenuMethod(isSideMenu){
            return this.isSideMenu = !isSideMenu
        },
        toggleSelected(select, index){
            if(this.clicked === index){
                this.clicked = null
            }

            this.clicked = index 
            this.name = select.name 
            this.image = select.image
        },
        changeLanguage(obj){
            if(obj=='jp'){ 
                this.selectedlang = 'ENG';
            }else{
                this.selectedlang = '日本語'; 
            }
            //localStorage.setItem('language',obj)
        },
        changeMenuActive(event){   
            var elm = document.getElementById(event);
            var offset = elm.offsetTop;             
            var element = "";
            var child = ""; 
            var Home_offsetHeight = document.getElementById('home').offsetHeight;
            if(event=="home"){
                if(offset >= 0 && offset < Home_offsetHeight){
                    element = document.getElementById(event+"-menu");
                    element.classList.add("active")
                    child = element.firstElementChild;
                    child.style.color = '#FEB302';
                    element = document.getElementById("about_us-menu");
                    element.classList.remove("active")
                    child = element.firstElementChild;
                    child.style.color = '#14042C';
                    element = document.getElementById("services-menu");
                    element.classList.remove("active")
                    child = element.firstElementChild;
                    child.style.color = '#14042C';
                    element = document.getElementById("careers-menu");
                    element.classList.remove("active")
                    child = element.firstElementChild;
                    child.style.color = '#14042C';
                    element = document.getElementById("contact-menu");
                    element.classList.remove("active")
                    child = element.firstElementChild;
                    child.style.color = '#14042C';
                }  
            }
            var about_offsetHeight  = document.getElementById('about_us').offsetHeight; 
            var about_offset        = about_offsetHeight + Home_offsetHeight;
            if(event=="about_us"){                
                if(offset >= Home_offsetHeight && offset < about_offset){
                    element = document.getElementById(event+"-menu");
                    element.classList.add("active")
                    child = element.firstElementChild;
                    child.style.color = '#FEB302';
                    element = document.getElementById("home-menu");
                    element.classList.remove("active")
                    child = element.firstElementChild;
                    child.style.color = '#14042C';
                    element = document.getElementById("services-menu");
                    element.classList.remove("active")
                    child = element.firstElementChild;
                    child.style.color = '#14042C';
                    element = document.getElementById("careers-menu");
                    element.classList.remove("active")
                    child = element.firstElementChild;
                    child.style.color = '#14042C';
                    element = document.getElementById("contact-menu");
                    element.classList.remove("active")
                    child = element.firstElementChild;
                    child.style.color = '#14042C';
                    
                }     
            }
            var services_offsetHeight = document.getElementById('services').offsetHeight;
            var services_offset        = services_offsetHeight + about_offset;  
            if(event=="services"){          
                if(offset >= about_offsetHeight && offset < services_offset){ 
                    element = document.getElementById(event+"-menu");
                    element.classList.add("active")
                    child = element.firstElementChild;
                    child.style.color = '#FEB302';
                    element = document.getElementById("home-menu");
                    element.classList.remove("active")
                    child = element.firstElementChild;
                    child.style.color = '#14042C';
                    element = document.getElementById("about_us-menu");
                    element.classList.remove("active")
                    child = element.firstElementChild;
                    child.style.color = '#14042C';
                    element = document.getElementById("careers-menu");
                    element.classList.remove("active")
                    child = element.firstElementChild;
                    child.style.color = '#14042C';
                    element = document.getElementById("contact-menu");
                    element.classList.remove("active")
                    child = element.firstElementChild;
                    child.style.color = '#14042C';
                }
            }   
            var careers_offsetHeight    = document.getElementById('careers').offsetHeight;
            var careers_offset          = careers_offsetHeight + services_offset;
            if(event=="careers"){
                if(offset > services_offsetHeight && offset < careers_offset){ 
                    element = document.getElementById(event+"-menu");
                    element.classList.add("active")
                    child = element.firstElementChild;
                    child.style.color = '#FEB302';
                    element = document.getElementById("home-menu");
                    element.classList.remove("active")
                    child = element.firstElementChild;
                    child.style.color = '#14042C';
                    element = document.getElementById("about_us-menu");
                    element.classList.remove("active")
                    child = element.firstElementChild;
                    child.style.color = '#14042C';
                    element = document.getElementById("services-menu");
                    element.classList.remove("active")
                    child = element.firstElementChild;
                    child.style.color = '#14042C';
                    element = document.getElementById("contact-menu");
                    element.classList.remove("active")
                    child = element.firstElementChild;
                    child.style.color = '#14042C';
                } 
            }
            var contact_offsetHeight = document.getElementById('contact').offsetHeight;   
            var contact_offset          = contact_offsetHeight + careers_offset; 
            if(event=="contact"){ 
                if(offset > careers_offsetHeight && offset < contact_offset){ 
                    element = document.getElementById(event+"-menu");
                    element.classList.add("active")
                    child = element.firstElementChild;
                    child.style.color = '#FEB302';
                    element = document.getElementById("home-menu");
                    element.classList.remove("active")
                    child = element.firstElementChild;
                    child.style.color = '#14042C';
                    element = document.getElementById("about_us-menu");
                    element.classList.remove("active")
                    child = element.firstElementChild;
                    child.style.color = '#14042C';
                    element = document.getElementById("services-menu");
                    element.classList.remove("active")
                    child = element.firstElementChild;
                    child.style.color = '#14042C';
                    element = document.getElementById("careers-menu");
                    element.classList.remove("active")
                    child = element.firstElementChild;
                    child.style.color = '#14042C';
                }   
            }    
        }
    },
    computed: {
        shoppingCart() {
        return this.$store.state.cart;
        },
    },
};
</script>
<style>
@media (min-width: 992px){
    .navbar-expand-lg .navbar-collapse {
        top: 30px;
        position: relative;
    }
}
.dropdown-menu{
    font-weight: bold !important;
    left: -15px !important;
    font-size:16px !important;
    min-width: 7rem !important;
}
.dropdown-menu .show{
    width:50px !important;
}
.dropdown-item{
    font-weight: 500 !important;
}
.btn-image{
    color:#14042C !important;
    font-weight: 500 !important;
    font-family: "Jost", sans-serif;
}
.cmt-3{
    margin-top: 1.2rem !important;
}
</style>
