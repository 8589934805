// import { createApp } from "vue";
// import App from "./App.vue";
// import router from "./router";
// import store from "./store";
// import axios from "axios";
// import VueAxios from "vue-axios";
// import Toaster from "@meforma/vue-toaster";

// import Scrollspy from 'vue2-scrollspy';



// const app = createApp(App).use(router).use(Toaster).use(store);
// app.use(VueAxios, axios);
// app.use(AOS.init()).mount("#app");


import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Scrollspy from 'vue2-scrollspy';
import "./assets/custom.scss";
import BootstrapVue from 'bootstrap-vue'
import AOS from "aos";
import "aos/dist/aos.css";
import VueI18n from 'vue-i18n'
import messages from './lang'
var VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo)
Vue.use(AOS)
Vue.use(VueI18n)
Vue.use(BootstrapVue);

Vue.use(Scrollspy);
Vue.use(AOS.init());
Vue.config.productionTip = false;

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages
}) 

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
