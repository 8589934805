<template>
    <div class="faq-area ptb-75" id="careers">
        <div class="container">
            <div class="section-title">
                <h2>We are hiring </h2> 
            </div>
            <div class="row align-items-center">          
                <div class="col-lg-6 col-md-12">
                    <div class="faq-accordion career-accordion">
                        <accordion>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-button">
                                        Bilingual Strategy Planning Consultant / IT Manager
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <div class="mx-3">
                                        <ul>
                                            <li>18+ Years Industry Experience </li>
                                            <li>TOEIC Level : 800 and Japanese Level : Native</li>
                                            <li>Salary : 12M ~ 15M JPY</li>
                                        </ul>
                                    </div>
                                    <div class="mt-3 mx-3 justify-right">
                                        <a href="mailto:contact@bigtreeconsulting.jp" class="default-btn px-3 py-2">Send CV</a>
                                    </div>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-button">
                                        Bilingual IT Consultant / Lead
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <div class="mx-3">
                                        <ul>
                                            <li>15+ Years Business and Technolgy Experience </li>
                                            <li>TOEIC Level : 800 and Japanese Level : Native</li>
                                            <li>Salary : 10M ~ 12M JPY</li>
                                        </ul>
                                    </div>
                                    <div class="mt-3 mx-3 justify-right">
                                        <a href="mailto:contact@bigtreeconsulting.jp" class="default-btn px-3 py-2">Send CV</a>
                                    </div>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-button">
                                        Bilingual IT Engineers
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <div class="mx-3">
                                        <ul>
                                            <li>10+ Years Experience </li>
                                            <li>TOEIC Level : 800 and Japanese Level : Advanced (JLPT N1 Equal)</li>
                                            <li>Salary : 8M ~ 10M JPY</li>
                                        </ul>
                                    </div>
                                    <div class="mt-3 mx-3 justify-right">
                                        <a href="mailto:contact@bigtreeconsulting.jp" class="default-btn px-3 py-2">Send CV</a>
                                    </div>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-button">
                                        Bilingual SAP Techno/Functional Consultant
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <div class="mx-3">
                                        <ul>
                                            <li>5 ~ 10 Years Experience </li>
                                            <li>TOEIC Level : 800 and Japanese Level : Advanced (JLPT N2/N3 Equal)</li>
                                            <li>Salary : 7M ~ 9M JPY </li>
                                        </ul>
                                    </div>
                                    <div class="mt-3 mx-3 justify-right">
                                        <a href="mailto:contact@bigtreeconsulting.jp" class="default-btn px-3 py-2">Send CV</a>
                                    </div>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-button">
                                        Bilingual Software Engineers
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <div class="mx-3">
                                        <ul>
                                            <li>3 ~ 8 Years Experience </li>
                                            <li>TOEIC Level : 800 and Japanese Level : Advanced (JLPT N3/N4 Equal)</li>
                                            <li>Salary : 5M ~ 8M JPY  </li>
                                        </ul>
                                    </div>
                                    <div class="mt-3 mx-3 justify-right">
                                        <a href="mailto:contact@bigtreeconsulting.jp" class="default-btn px-3 py-2">Send CV</a>
                                    </div>
                                </template>
                            </accordion-item>
                        </accordion>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="career-image">
                        <img src="../assets/images/careers/careers-banner.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from "./Common/Accordion";
import AccordionItem from "./Common/AccordionItem";

export default {
    name: 'Faq',
    components: {
        Accordion,
        AccordionItem
    }
}
</script>
<style>
.career-image img{
    bottom: 23px;
    position: relative;
}
</style>