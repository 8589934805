<template>
    <div class="projects-details-area mb-10" id="about_us">
        <div class="container ptb-75">
            <div class="projects-details-desc" style="max-width: none !important;">
                <div class="article-projects-with-info">
                    <div class="row align-items-center">                               
                        <div class="text-center">                
                            <h2>{{$t('about_profile_head')}}</h2>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="projects-info-content">                                

                                <ul class="info-list mt-0">                                    
                                    <li>
                                        <div class="row" style="width:500px;">
                                            <div class="col-md-6">
                                                <span class="text-dark fw-bold fs-17px">
                                                    {{$t('sub_heading1')}}:
                                                </span> 
                                            </div>
                                            <div class="col-md-6 fs-17px">
                                                BigTree Consulting Inc.,
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <span class="text-dark fw-bold fs-17px">{{$t('sub_heading2')}}:</span>
                                            </div> 
                                            <div class="col-md-6 fs-17px">
                                                Yusuke Ooki
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="row">
                                            <div class="col-md-6 fs-17px">
                                                 <span class="text-dark fw-bold fs-17px">{{$t('sub_heading3_sub2')}}:</span>
                                            </div>
                                            <div class="col-md-6 fs-17px">
                                                Krishnakumar Sangameswaran
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="text-dark fw-bold fs-17px">{{$t('sub_heading4')}}:</div> 
                                            </div>
                                            <div class="col-md-6">
                                                <div class=" fs-17px">5F GinzaDaieiBuilding,<br />
                                                    1-16-7 Ginza,Chuo-ku,<br />
                                                    Tokyo - 104-0061.<br />
                                                    TEL: <a href="tel:+810343608694">03-4360-8694</a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <!-- <li><span>{{$t('sub_heading5')}}:</span><br/><i class="ri-checkbox-circle-line"></i> {{ $t('exe_director') }}:  Ooki Yusuke<br/>
                                    <i class="ri-checkbox-circle-line"></i> {{ $t('director') }} : Krishnakumar Sangameswaran</li>
                                    <li><span class="text-dark">TEL:</span> <a href="tel:+810343608694">03-4360-8694</a></li>-->
                                    <li>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <span class="text-dark fw-bold fs-17px">{{$t('sub_heading5')}}:</span>
                                            </div>
                                            <div class="col-md-6 fs-17px"> 
                                                February 1, 2022
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <span class="text-dark fw-bold fs-17px">{{$t('sub_heading6')}}:</span>
                                            </div>
                                            <div class="col-md-6 fs-17px"> 
                                                SBI Sumishin Net Bank, Ltd., Japan.
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <span class="text-dark fw-bold fs-17px">Corporate Identification Number:</span>
                                            </div>
                                            <div class="col-md-6 fs-17px"> 
                                                2010001224363
                                            </div>
                                        </div>
                                    </li> 
                                    <!-- <li>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <span class="text-dark fw-bold fs-17px">{{$t('sub_heading8')}}:</span>
                                            </div>
                                            <div class="col-md-6 fs-17px"> 
                                                
                                            </div>
                                        </div>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="about-image" style="margin-top: 80px;">
                                <img src="../assets/images/about/about-bigtree.jpg" alt="image" style="height: 640px;width: 100%;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="projects-details-shape">
            <img src="../assets/images/projects-details/line-shape.png" alt="image">
        </div> -->
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>
<style>
.about-image img{
    bottom: 40px;
    position: relative;
}
.fs-17px{
    font-size:17px !important;
}
.ptb-75{
    padding-top:75px;
    padding-bottom:75px;
}
</style>