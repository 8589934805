<template>
    <div class="main-banner-area ptb-75" id="home">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="main-banner-content"  style="margin-top:5rem;">
                        <!-- <span data-aos="fade-right" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">{{ $t('content1') }}</span> -->
                        <!-- <span data-aos="fade-right" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">Welcome to</span> -->
                        <!-- <h1 data-aos="fade-right" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">{{ $t('content2') }}</h1> -->
                        <h1 data-aos="fade-right" class="mt-0 pt-0" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">BigTree Consulting Inc.,</h1>
                        <!-- <p data-aos="fade-right" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">{{ $t('content_para') }}</p> -->
                        <!-- <p data-aos="fade-right" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">Place Your Content Here.</p> -->
                        
                        
                       
                    </div> 
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="main-banner-image">
                        <!-- <div class="pt-100"></div> -->
                        <img src="../assets/images/main-banner/banner.png" alt="image" data-aos="fade-down" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">

                        <div class="banner-circle">
                            <div></div>
                            <!-- <img src="../assets/images/main-banner/banner-circle.png" alt="image" data-aos="fade-down" data-aos-delay="100" data-aos-duration="1000" data-aos-once="true"> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!--
        <div class="banner-shape-1">
            <img src="../assets/images/main-banner/banner-shape-1.png" alt="image">
        </div>
        <div class="banner-shape-2">
            <img src="../assets/images/main-banner/banner-shape-2.png" alt="image">
        </div>
        <div class="banner-dot-shape-1">
            <img src="../assets/images/main-banner/dot-1.png" alt="image">
        </div>
        <div class="banner-dot-shape-2">
            <img src="../assets/images/main-banner/dot-2.png" alt="image">
        </div>
        <div class="banner-dot-shape-3">
            <img src="../assets/images/main-banner/dot-3.png" alt="image">
        </div>
        <div class="banner-dot-shape-4">
            <img src="../assets/images/main-banner/dot-4.png" alt="image">
        </div>
        <div class="banner-dot-shape-5">
            <img src="../assets/images/main-banner/dot-5.png" alt="image">
        </div>
         <div class="lines">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
        </div> -->
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>
<style>
.main-banner-area{
    padding-top:80px !important;
}
</style>