import Vue from "vue";
import VueRouter from "vue-router";

import Index2 from "./pages/index2.vue";
import { i18n } from '../main'
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index-2",
    component: Index2
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  i18n.locale = localStorage.getItem('language') || 'en'
  return next()
})

export default router;
