
<template>
    <div class="talk-area ptb-75" id="contact">
        <div class="container">
            <div class="section-title">
                <h2>{{ $t('talk_head2')}} </h2>
            </div>
            <div class="row align-items-center pb-2">          
                <div class="col-lg-6 col-md-12">
                    <div class="talk-content mt-0 pt-0">
                        <form id="contactFormTwo" @submit="onSubmit" class="pt-0 mt-0">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" :placeholder=" $t('input_1') ">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" :placeholder=" $t('input_2')" >
                                    </div>
                                </div>
                                <!-- <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="mobile" id="mobile" class="form-control" maxlength="12" :placeholder=" $t('input_4')" >
                                    </div>
                                </div> -->
                                <!-- <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="location" name="location" id="location" class="form-control" maxlength="20" :placeholder=" $t('input_5')" >
                                    </div>
                                </div> -->
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" maxlength="1500"  rows="6" :placeholder= "$t('input_3')" ></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <vue-recaptcha ref="recaptcha" @verify="onVerify" sitekey="6LdJBJcpAAAAABo6uxYEdhFJAJUASbqN5gbQ-Mpc"></vue-recaptcha>
                                </div> 
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <div id="error-alert"></div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 text-end">
                                    <!-- <button type="submit" class="default-btn">{{ $t('button') }}<span></span></button> -->
                                    <button type="submit" class="default-btn" id="submit-button"><b-spinner id="spinner" style="margin-bottom:2px;margin-right:4px;color:black;display:none"></b-spinner> <div id="btn-content">{{ $t('button') }}</div></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="talk-image" data-tilt>
                        <img src="../assets/images/talk/talk-banner.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import { VueRecaptcha } from 'vue-recaptcha';
//document.querySelector('#error-alert').style.display ='block';
export default {
    name: 'Talk',
    data() {
        return {
            text: '',
            form: {
                name: '',
                email: '',
                message: '',
                // mobile: '',
                // location: '',
                robot: false
            },
            show: true,
        };
    },
    components: { 
        VueRecaptcha 
    },
    methods: { 
        // onEvent() {
            // when you need a reCAPTCHA challenge
            // this.$refs.recaptcha.execute();
        // },
        onSubmit(e) {
            e.preventDefault()
            const config = {
                responseType: 'text',
            };
            var name        = document.querySelector('#name').value;
            var email       = document.querySelector('#email').value;
            var message     = document.querySelector('#message').value;
            var error       = "";
            var flag        = true;
            if(!name){
                if(error){error += ", ";}
                error += "Enter The Name";
                flag = false;
            }            
            if(!email){
                if(error){error += ", ";}
                error += "Enter The Email ID";
                flag = false;
            }
            if(!message){
                if(error){error += ", ";}
                error += "Enter The Message";
                flag = false;
            }
            if (!this.form.robot) {
                if(error){error += ", ";}
                error += "Captcha Not verified";
                flag = false;
            }
            if(flag){
                document.getElementById("spinner").style.display        = "block";
                document.getElementById("submit-button").style.cursor   = "not-allowed";
                document.getElementById("btn-content").innerHTML        = "Please Wait..";
                document.getElementById("submit-button").disabled       = true;
                document.getElementById("error-alert").innerHTML        = "<div class='sb-msg' style='color:#1c961c'></div>";
                var obj = new Object();
                obj["name"]     = document.querySelector('#name').value;
                obj["email"]    = document.querySelector('#email').value;
                obj["message"]  = document.querySelector('#message').value;
                // obj["mobile"]   = document.querySelector('#mobile').value;
                // obj["location"] = document.querySelector('#location').value;
                var myJSON = JSON.stringify(obj);
                axios.post('/smtp/enquiry.php',"data="+myJSON,config)
                .then(response => {
                    document.getElementById("error-alert").innerHTML = "<div class='sb-msg' style='color:#1c961c'>"+response.data+"</div>";
                    document.querySelector('#name').value="";
                    document.querySelector('#email').value="";
                    document.querySelector('#message').value="";
                    // document.querySelector('#mobile').value="";
                    // document.querySelector('#location').value="";
                    setTimeout(() => {
                        document.querySelector('#error-alert').style.display    = 'none';
                        document.getElementById("spinner").style.display        = "none";
                        document.getElementById("btn-content").innerHTML        = "Send Message";                 
                        document.getElementById("submit-button").style.cursor   = "default";
                        document.getElementById("submit-button").disabled       = false;
                        location.reload();
                    }, 1500);
                }) 
                .catch(error => {
                    error
                    document.getElementById("error-alert").innerHTML = "<div class='sb-msg' style='color:#d31515'>Enquiry Not Sent!</div>";
                    document.getElementById("spinner").style.display = "none";
                    document.getElementById("btn-content").innerHTML = "Send Message";
                    document.getElementById("submit-button").disabled = false;
                }); 
            }else{
                document.getElementById("error-alert").innerHTML = "<div class='sb-msg' style='color:#d31515'>"+error+"</div>";
                document.getElementById("submit-button").disabled = false;
            }
        },   
        onVerify: function (response) {
            if (response) this.form.robot = true;
        },
    },
}
</script>
<style>
.form-control{
    border: 1px solid #FEB302 !important;
}
.ptbc-200{
    padding-top: 200px !important;
}
.talk-image img{
    bottom: 0px;
    position: relative;
}
.talk-area{
    background-color: #ffffff !important;
}
</style>