
<template>
  <div>
      <!-- <Topbar /> -->
      <NavbarStyleTwo />
      <div v-scroll-spy>
          <MainBanner />      
        
        
        <About />
        
        <Services />
        
        <Careers />
        <!-- <Overview /> -->
        <Talk />
        <Map />
        
        <Footer class="margin-zero" />
    </div>
  </div>
</template>
<script>

  // import Topbar from '../../components/Topbar'
  import NavbarStyleTwo from '../../components/NavbarStyleTwo'
  import MainBanner from '../../components/MainBanner'
  import About from '../../components/About'

  import Services from '../../components/Services'
  // import Overview from '../../components/Overview'
  import Talk from '../../components/Talk'
  import Careers from '../../components/Careers'
  import Map from '../../components/Map'
  import Footer from '../../components/Footer'

  
  /**
   * Index-2 component
   */
  export default {
    components: {
        // Topbar,
        NavbarStyleTwo,
        MainBanner,
        
        About,
        Services,
        // Overview,
        Talk,
        Careers,
        Map,
        Footer,
    }
  };
  </script>
<style>
.ptb-200{
    padding-top:195px !important; 
}
</style>