<template>
    <footer class="footer-area with-black-background">
        <div class="copyright-area mt-0">
            <div class="container">
                <div class="copyright-area-content">
                    <p>
                        <!-- &copy; {{currentYear}} BigTree Consulting Inc. {{ $t('footer_1') }}.  -->
                        &copy; Copyright BigTree Consulting Inc. All Rights Reserved.
                    </p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>