<template>
    <div class="services-area ptb-75" id="services">
        <div class="container">
            <div class="section-title">
                
                <h2>{{ $t('service_head') }}</h2>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-9">
                    <div class="services-item">
                        <div class="services-image">
                            <router-link to="">
                                <img src="../assets/images/services/business-strategy-planning.jpg" alt="Business Strategy Planning, Japan" />
                            </router-link>
                        </div>
                        <div class="services-content px-0 py-2">
                            <div class="serv-desc">
                                <div class="faq-accordion">
                                    <accordion>
                                        <accordion-item>
                                            <template v-slot:accordion-trigger>
                                                <button class="accordion-button">
                                                    {{ $t('service_sub_head1') }}
                                                </button>
                                            </template>
                                            <template v-slot:accordion-content>
                                                <p class="text-justify">{{ $t('service_sub_head1_desc01') }}</p>
                                                <p class="text-justify">{{ $t('service_sub_head1_desc02') }}</p>
                                            </template>
                                        </accordion-item>
                                    </accordion>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-9">
                    <div class="services-item">
                        <div class="services-image">
                            <router-link to="">
                                <img src="../assets/images/services/business-process-consulting.jpg" alt="Business Process Consulting, Japan" />
                            </router-link>
                        </div>
                        <div class="services-content px-0 py-2">
                            <div class="faq-accordion">
                                <accordion>
                                    <accordion-item>
                                        <template v-slot:accordion-trigger>
                                            <button class="accordion-button">
                                                {{ $t('service_sub_head2') }}
                                            </button>
                                        </template>
                                        <template v-slot:accordion-content>
                                            <p class="text-justify">{{ $t('service_sub_head1_desc2') }}</p>
                                        </template>
                                    </accordion-item>
                                </accordion>  
                            </div>                        
                        </div>
                    </div>
                </div>
                
               

                <div class="col-lg-4 col-md-9">
                    <div class="services-item">
                        <div class="services-image">
                            <router-link to="">
                                <img src="../assets/images/services/digital-transformation.jpg" alt="Digital Transformation, Japan" />
                            </router-link>
                        </div>
                        <div class="services-content px-0 py-2">
                            <div class="faq-accordion">
                                <accordion>
                                    <accordion-item>
                                        <template v-slot:accordion-trigger>
                                            <button class="accordion-button">
                                                {{ $t('service_sub_head3') }}
                                            </button>
                                        </template>
                                        <template v-slot:accordion-content>
                                            <p class="text-justify">{{ $t('service_sub_head1_desc3') }}</p>
                                        </template>
                                    </accordion-item>
                                </accordion>  
                            </div>                       
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-9">
                    <div class="services-item">
                        <div class="services-image">
                            <router-link to="">
                                <img src="../assets/images/services/it-consulting.jpg" alt="IT Consulting, Japan" />
                            </router-link>
                        </div>
                        <div class="services-content px-0 py-2">
                            <div class="faq-accordion">
                                <accordion>
                                    <accordion-item>
                                        <template v-slot:accordion-trigger>
                                            <button class="accordion-button">
                                                {{ $t('service_sub_head4') }}
                                            </button>
                                        </template>
                                        <template v-slot:accordion-content>
                                            <p class="text-justify">{{ $t('service_sub_head1_desc04') }}</p>
                                            <p class="text-justify">{{ $t('service_sub_head1_desc05') }}</p>
                                        </template>
                                    </accordion-item>
                                </accordion>
                                <br>  
                            </div> 
                        </div>
                    </div>
                </div>

                 <div class="col-lg-4 col-md-9">
                    <div class="services-item">
                        <div class="services-image">
                            <router-link to="">
                                <img src="../assets/images/services/system-development-and-maintenance.jpg" alt="System Development and Maintenance, Japan" />
                            </router-link>
                        </div>
                        <div class="services-content px-0 py-2">
                            <div class="faq-accordion">
                                <accordion>
                                    <accordion-item>
                                        <template v-slot:accordion-trigger>
                                            <button class="accordion-button">
                                                {{ $t('service_sub_head5') }}
                                            </button>
                                        </template>
                                        <template v-slot:accordion-content>
                                            <p class="text-justify">{{ $t('service_sub_head1_desc5') }}</p>
                                        </template>
                                    </accordion-item>
                                </accordion>  
                            </div>                          
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-9">
                    <div class="services-item">
                        <div class="services-image">
                            <router-link to="">
                                <img src="../assets/images/services/sap-solutions-and-services.jpg" alt="SAP Solutions and Services, Japan" />
                            </router-link>
                        </div>
                        <div class="services-content px-0 py-2">
                            <div class="faq-accordion">
                                <accordion>
                                    <accordion-item>
                                        <template v-slot:accordion-trigger>
                                            <button class="accordion-button">
                                                {{ $t('service_sub_head6') }}
                                            </button>
                                        </template>
                                        <template v-slot:accordion-content>
                                            <p class="text-justify">{{ $t('service_sub_head1_desc6') }}</p>
                                        </template>
                                    </accordion-item>
                                </accordion>  
                                <br>
                            </div>
                        </div>
                    </div>
                </div>      
            </div>
        </div>

        <!-- <div class="services-shape-1">
            <img src="../assets/images/services/services-shape-1.png" alt="image">
        </div>
        <div class="services-shape-2">
            <img src="../assets/images/services/services-shape-2.png" alt="image">
        </div> -->
    </div>
</template>

<script>
import Accordion from "./Common/Accordion";
import AccordionItem from "./Common/AccordionItem";
export default {
    name: 'Services',
    components: {
        Accordion,
        AccordionItem
    }
}
</script>
<style>
.services-item{
    background-color: #ffffff !important; 
    border:2px solid #316c32 !important;
}
.services-item:hover{
    border:2px solid #feb302 !important;
}
.services-item .services-content h3 a{
    color:#14042C !important;
}
.serv-desc{
    text-align: justify !important;
}
.text-justify{
    text-align: justify !important;
}
</style>